import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgSearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <path
      d="M8.943 3a5.943 5.943 0 0 1 5.943 5.943c0 1.472-.54 2.825-1.427 3.867l.247.247h.723L19 17.63 17.629 19l-4.572-4.571v-.723l-.247-.247a5.96 5.96 0 0 1-3.867 1.427A5.943 5.943 0 1 1 8.943 3m0 1.829a4.097 4.097 0 0 0-4.114 4.114 4.097 4.097 0 0 0 4.114 4.114 4.097 4.097 0 0 0 4.114-4.114 4.097 4.097 0 0 0-4.114-4.114"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgSearchIcon);
export default Memo;
